import { type FC, type PropsWithChildren } from 'react'
import { cx } from 'utils/cx'

import { CarouselProvider } from '../context/CarouselProvider'
import type { CarouselProps } from '../types'

import styles from './Carousel.module.scss'

export const Carousel: FC<PropsWithChildren<CarouselProps>> = ({
	dataTestId,
	children,
	className,
	...props
}) => {
	return (
		<section
			className={cx(styles.carousel, className)}
			data-testid={dataTestId}
		>
			<CarouselProvider {...props}>{children}</CarouselProvider>
		</section>
	)
}
